<template>
  <div class="dialog-container">
    <el-dialog
      title="编辑"
      :visible.sync="show"
      @open="openDialog"
      :before-close="closeDialog"
      center
      :close-on-click-modal="false"
      width="500px"
    >
      <el-form ref="form" :model="form" :inline="true" :rules="rules">
        <el-form-item label="用户名：" prop="userName">
          <el-input
            clearable
            v-model="form.userName"
            placeholder="请输入用户名"
          ></el-input>
        </el-form-item>
        <el-form-item label="手机号：" prop="phonenumber">
          <el-input
            clearable
            v-model="form.phonenumber"
            placeholder="请输入手机号"
          ></el-input>
        </el-form-item>
      </el-form>
      <template slot="footer">
        <el-button class="footer-confirm" @click="sureEvent">{{
          $t("sure")
        }}</el-button>
        <!-- 如果没特别需求，统一使用mixin里面的关闭方法 -->
        <el-button class="footer-cancel" @click="closeDialog">{{
          $t("cancel")
        }}</el-button>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import { mapState } from "vuex";
import mixin from "@/mixins/dialogMixin";
import EditDialogClass from "./editDialog";
import apis from "@/apis";

export default {
  mixins: [mixin],
  props: {
    show: {
      required: true,
      type: Boolean,
    },
    fileData: {
      required: true,
    },
    Language: {
      required: false,
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      form: new EditDialogClass("form"), // 实例化一个表单的变量
      rules: new EditDialogClass("rule", this.Language), // 实例化一个表单的规则
    };
  },
  computed: {
    ...mapState(["DROPDOWNBOX"]),
  },
  methods: {
    /**
     * @function 初始化弹窗
     */
    init() {
      this.$refs.form.resetFields();
      this.form = new EditDialogClass("form");
      console.log("我初始化了");
    },
    /**
     * @function 点击确定按钮，先执行业务，完了调关闭方法，不要写两次关闭方法
     */
    sureEvent() {
      // 这里写业务需求
      this.$refs.form.validate((valid) => {
        const params = {
          ...this.form,
          userId: this.fileData.userId,
        };
        console.log(params);
        if (valid) {
          this.$http.put(apis.sysMemberUpdate, { ...params }).then((res) => {
            if (res.data.code === 0) {
              // 调用关闭方法，不要再写一次
              this.closeDialog();
            }
          });
        }
      });
      console.log(`我执行了点确定的业务需求`);
    },
    getData(data) {
      this.form = data;
    },
  },
};
</script>
<style lang="less">
@import "../../../../assets/styles/mixin.less";
</style>
