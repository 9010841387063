<template>
  <div class="wrap">
    <div class="search-container">
      <el-row>
        <el-form ref="form" :model="form" label-width="80px">
          <el-col :span="6">
            <el-form-item label="姓名">
              <el-input
                clearable
                placeholder="请输入"
                v-model="form.userName"
              />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="手机号">
              <el-input
                clearable
                placeholder="请输入"
                v-model="form.phonenumber"
              />
            </el-form-item>
          </el-col>
        </el-form>
        <el-col :span="12" style="text-align: right">
          <el-button @click="searchEvent" class="search-button">{{
            $t("search")
          }}</el-button>
          <el-button @click="cleanForm" class="clean-button">{{
            $t("clean")
          }}</el-button>
        </el-col>
      </el-row>
    </div>
    <div class="table-container">
      <el-table
        :data="tableData"
        :height="tableHeight"
        style="width: 100%"
        :row-class-name="tableRowClassName"
        border
      >
        <el-table-column
          prop="userName"
          align="center"
          header-align="center"
          show-overflow-tooltip
          min-width="165"
          label="姓名（微信名）"
        >
          <template slot-scope="scope">
            <div style="display: flex; align-items: center">
              <img
                :src="scope.row.avatar"
                style="height: 50px; width: 50px; float: left"
                alt=""
              />
              <div style="margin-left: 20px">{{ scope.row.userName }}</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="phonenumber"
          min-width="148"
          align="center"
          label="手机号"
        >
        </el-table-column>
        <el-table-column
          prop="checkInTimes"
          min-width="168"
          align="center"
          label="打卡次数"
        >
          <template slot-scope="scope">
            <span class="clock-total" v-if="scope.row.checkInTimes">
              {{ scope.row.publishBy }}
            </span>
            <span class="clock-total" v-else>0</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="publishTime"
          align="center"
          min-width="168"
          label="状态"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.status === '0'" class="status-normal">
              正常
            </span>
            <span v-else class="status-disable"> 禁用 </span>
          </template>
        </el-table-column>
        <el-table-column
          prop="createTime"
          align="center"
          min-width="120"
          label="注册时间"
        >
        </el-table-column>
        <el-table-column align="center" min-width="120" label="操作">
          <template slot-scope="scope">
            <el-button type="text" @click="openEditDialog(scope.row)"
              >编辑</el-button
            >
            <el-button
              type="text"
              style="color: #e02020"
              v-if="scope.row.status === '0'"
              @click="toDisable(scope.row, 1)"
              >禁用</el-button
            >
            <el-button
              type="text"
              style="color: #6dd400"
              v-else
              @click="toDisable(scope.row, 0)"
              >启用</el-button
            >
            <el-button type="text" @click="toFaceRecognition(scope.row)"
              >人脸识别</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="bottom-con">
        <div class="page-container">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="form.pageNum"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="form.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="form.total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <!-- 编辑确认弹窗 -->
    <edit-dialog
      ref="editDialog"
      :Language="Language"
      :show="editDialog.visible"
      :fileData="editDialog.data"
      @sureDialog="sureConfirmDialog"
      @closeDialog="changeEditDialog"
    ></edit-dialog>
    <!-- 人脸识别图片预览弹窗 -->
    <el-dialog
      title="人脸识别"
      :visible.sync="imgDialog.visible"
      :before-close="imgClose"
      center
      :close-on-click-modal="false"
      width="500px"
    >
      <div class="img-box">
        <img :src="url" alt="" class="img-content" />
      </div>
      <div class="close-btn">
        <el-button class="footer-cancel" @click="closeDialog">{{
          $t("close")
        }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import editDialog from "./dialog/editDialog.vue";
import UserSearchClass from "./userSearchClass";
import apis from "@/apis";
import mixin from "@/mixins/index";
import { mapState } from "vuex";

export default {
  components: { editDialog },
  mixins: [mixin],
  data() {
    return {
      form: new UserSearchClass("form"), // 实例化一个表单的变量
      tableData: [],
      editDialog: {
        visible: false,
        data: {},
      },
      imgDialog: {
        visible: false,
      },
      url: "",
    };
  },
  computed: {
    ...mapState(["Language", "TOKEN"]),
  },
  methods: {
    /**
     * @function 打开编辑弹窗
     * @param {String} fileName 文件名
     */
    openEditDialog(data) {
      this.editDialog.visible = true;
      const { userName, phonenumber, userId } = data;
      this.changeEditDialog(true, { userName, phonenumber, userId });
    },
    toFaceRecognition(data) {
      this.url = "";
      this.$http.get(`${apis.getUserFaceInfo}/${data.userId}`).then((res) => {
        if (res.data.code === 0) {
          this.imgDialog.visible = true;
          this.url = res.data.data.faceImgUrl;
        }
      });
    },
    changeEditDialog(dialogStatus = false, data = {}) {
      this.editDialog.data = data;
      this.editDialog.visible = dialogStatus;
      if (!dialogStatus) {
        this.searchEvent();
      } else {
        this.$nextTick(() => {
          this.$refs.editDialog.getData(data);
        });
      }
    },
    closeDialog() {
      this.imgDialog.visible = false;
    },
    /**
     * @function 搜索方法
     */
    searchData() {
      this.$http
        .post(apis.sysMemberList, {
          ...this.form,
        })
        .then((res) => {
          if (res.data.code === 0) {
            this.tableData = res.data.rows;
            this.form.total = res.data.total;
          }
        });
    },
    /**
     * @function 清空搜索栏
     */
    cleanForm() {
      this.form = new UserSearchClass("form");
      this.$nextTick(() => {
        this.searchEvent();
      });
    },
    sureConfirmDialog() {},
    imgClose() {
      this.imgDialog.visible = false;
      this.url = "";
    },
    toDisable(data, type) {
      const typeContent = type === 1 ? "禁用" : "启用";
      this.$confirm(`"此操作将${typeContent}该条数据, 是否继续?"`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        confirmButtonClass: "confirm-button",
        cancelButtonClass: "cancel-button",
        type: "warning",
      })
        .then(() => {
          this.$http
            .put(`${apis.sysMemberStatus}?status=${type}&userId=${data.userId}`)
            .then((res) => {
              if (res.data.code === 0) {
                this.searchEvent();
              }
            });
        })
        .catch(() => {});
    },
  },
};
</script>
<style lang="less" scoped>
@import "../../../assets/styles/mixin.scoped.less";
.table-container {
  .clock-total {
    color: #439bff;
  }
  .status-normal {
    color: #6dd400;
  }
  .status-disable {
    color: #e02020;
  }
  .bottom-con {
    text-align: right;
  }
}
.img-box {
  vertical-align: middle;
  text-align: center;
  .img-content {
    width: 300px;
    height: 300px;
  }
}
.close-btn {
  text-align: center;
  padding: 10px 0px;
}
</style>
<style lang="less">
@import "../../../assets/styles/mixin.less";
.cancel-button {
  background: rgba(234, 236, 241, 1);
  color: rgba(62, 103, 174, 1);
  &:hover {
    background: rgba(234, 236, 241, 1);
    color: rgba(62, 103, 174, 1);
  }
}
.confirm-button {
  background: #3e67ae!important;
  border-color: #3e67ae!important;
}
</style>
