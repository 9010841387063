/*
 * @Author: zhongtao
 * @LastEditors: zhongtao
 */

class searchFrom {
    validatePhone = (rule, value, callback) => {
        const phone = value.replace(/\s/g, "");//去除空格
        //校验手机号，号段主要有(不包括上网卡)：130~139、150~153，155~159，180~189、170~171、176~178。14号段为上网卡专属号段
        const regs = /^((13[0-9])|(17[0-1,3,6-8])|(15[^4,\\D])|(18[0-9]))\d{8}$/;
        const reg = /^\d{3}-\d{8}$|^\d{4}-\d{7,8}$/;
        if (!value) {
            callback([new Error('请输入联系电话')]);
        } else if (!regs.test(phone) && !reg.test(phone)) {
            callback([new Error('联系号码输入不合法')]);
       } else {
           callback()
       }
    }

    /* eslint no-underscore-dangle: 0 */
    constructor(type) {
      if (type === 'form') {
        this.fileName = ''
        this.phone = ''
      } else if (type === 'rule') {
        this.userName = [
          {
            required: true,
            message: '请输入用户名',
            trigger: 'blur',
          },
        ]
        this.phonenumber = [
            { required: true, message: "请输入联系电话", trigger: ['blur', 'change'] },
            {
              min: 11,
              max: 11,
              message: '长度在11个字符',
              trigger: ['blur', 'change'],
            },
          ]
      }
    }
  }
  export default searchFrom
